.markdown {
  @apply text-lg text-gray-700 leading-normal;
  & > * + *,  & li + li, & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-black font-bold;
  }
  & a {
    @apply text-black font-semibold;
  }
  & strong a {
    @apply font-bold;
  }
  & h2 {
    @apply leading-tight text-xl font-bold text-gray-800 mb-2 mt-10;
  }
  & h3 {
    @apply leading-tight text-lg font-bold text-gray-800 mt-8 -mb-2;
  }
  & code {
    @apply font-mono text-sm inline text-gray-300 px-1;
  }
  & pre code {
    @apply block bg-indigo-800 p-4 rounded;
  }
  & blockquote {
    @apply border-l-4 border-gray-400 pl-4 italic;
  }
  & ul, & ol {
    @apply pl-5;
    @screen sm {
      @apply pl-10;
    }
  }
}
