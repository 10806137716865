@tailwind base;

@tailwind components;

@tailwind utilities;

/* CustomModal.css */

.custom-modal {
    position: absolute;
    top: 50%; /* Center the modal vertically */
    left: 50%; /* Center the modal horizontally */
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 20px;
    background-color: #fff; /* Modal background color */
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure the modal is above other elements */
  
    /* Add more styles as needed */
  }
  
  .custom-modal-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Background color of the overlay with opacity */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999; /* Ensure the overlay is below the modal but above other elements */
  
    /* Apply backdrop blur effect (optional) */
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  
    /* Add more styles as needed */
  }
  